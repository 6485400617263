import * as React from "react";
import { ThemeColors } from "../styles";
// tslint:disable-next-line:no-var-requires
const logo = require("../assets/logo256.png");

interface IComponentState {}

interface IComponentProps {}

const pageContainerStyle: React.CSSProperties = {
  height: "100%",
  backgroundImage: "linear-gradient(to bottom right, #2c2828, #3255c2)",
  display: "flex",
  alignContent: "center",
  flexDirection: "column",
  justifyContent: "center",
  backgroundColor: ThemeColors.AccentBackground,
  color: ThemeColors.PrimaryForeground
};

const textContainerStyle: React.CSSProperties = {
  height: "100px",
  paddingTop: "40px",
  maxWidth: "800px",
  textAlign: "center",
  alignSelf: "center"
};

export default class SupportPage extends React.Component<
  IComponentProps,
  IComponentState
> {
  public render() {
    return (
      <div style={pageContainerStyle}>
        <img
          style={{ width: "40vw", maxWidth: "256px", alignSelf: "center" }}
          src={logo}
        />
        <div style={textContainerStyle}>
          {
            "Thanks for using Clean Calendar, if you need help or have suggestions please email us at "
          }
          <a href="mailto:support@cleancalendar.app">
            support@cleancalendar.app
          </a>
        </div>
        <div style={textContainerStyle}>
          For macOS, if you would like Clean Calendar to start whenever your
          computer starts see{" "}
          <a href="https://support.apple.com/kb/PH25590" target="_blank">
            this Apple support article
          </a>.
        </div>
      </div>
    );
  }
}
