import * as React from "react";
import { AlertsContext, IAlertsContext } from "../../contexts/alerts-context";
import ShowAppointmentsAlert from "./ShowAppointmentsAlert";

interface IComponentProps {
  alertsContext: IAlertsContext;
}

const AlertBannerContainer: React.SFC<IComponentProps> = props => {
  if (props.alertsContext.enableShowAppointmentsAlert) {
    return (
      <ShowAppointmentsAlert
        showAppointmentsAlertResult={
          props.alertsContext.showAppointmentsAlertResult
        }
      />
    );
  }

  return null;
};

export default props => (
  <AlertsContext.Consumer>
    {context => <AlertBannerContainer {...props} alertsContext={context} />}
  </AlertsContext.Consumer>
);
