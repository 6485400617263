import { isSameDay, isToday } from "date-fns";
import * as React from "react";
import {
  ISelectedDateContext,
  SelectedDateContext
} from "../contexts/selectedDate-context";
import { ThemeColors } from "../styles";

interface IComponentState {}
interface IComponentProps {
  date: Date;
  selectedDateContext: ISelectedDateContext;
  partOfOtherMonth?: boolean;
}

const monthDayContainerStyle: React.CSSProperties = {
  height: "100%",
  backgroundColor: ThemeColors.PrimaryBackground,
  color: ThemeColors.AccentForeground,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  alignContent: "center"
};

const dateRowStyle: React.CSSProperties = {
  fontSize: "5vh",
  userSelect: "none"
};

class MonthDayNumberOnly extends React.Component<
  IComponentProps,
  IComponentState
> {
  constructor(props) {
    super(props);

    this.state = {};
  }

  public shouldComponentUpdate(
    newProps: IComponentProps,
    newState: IComponentState
  ) {
    if (!isSameDay(this.props.date, newProps.date)) {
      return true;
    }

    if (
      !isSameDay(
        this.props.selectedDateContext.today,
        newProps.selectedDateContext.today
      )
    ) {
      return true;
    }

    return false;
  }

  public render() {
    const todayStyle = { ...monthDayContainerStyle };
    if (isToday(this.props.date)) {
      todayStyle.backgroundColor = ThemeColors.AccentForeground;
      todayStyle.color = ThemeColors.PrimaryForeground;
    }

    const dateRowStyleWithOpacity = {
      ...dateRowStyle,
      opacity: this.props.partOfOtherMonth ? 0.5 : 1.0
    };
    return (
      <div style={todayStyle} className="opacity-on-hover">
        <div style={dateRowStyleWithOpacity}>
          {this.props.date.getUTCDate()}
        </div>
      </div>
    );
  }
}

export default props => (
  <SelectedDateContext.Consumer>
    {context => <MonthDayNumberOnly {...props} selectedDateContext={context} />}
  </SelectedDateContext.Consumer>
);
