import Paper from "@material-ui/core/Paper";
import { ISimpleAlert } from "../../models/Alerts";
import * as React from "react";
import { ThemeColors } from "../../styles";

interface IComponentProps {
  alert: ISimpleAlert;
}

const contentContainerStyle: React.CSSProperties = {
  color: ThemeColors.PrimaryForeground,
  backgroundColor: ThemeColors.PrimaryBackground,
  padding: "10px"
};

export default class SimpleDrawerAlert extends React.PureComponent<
  IComponentProps,
  {}
> {
  public render() {
    return (
      <Paper elevation={1} onClick={this.handleAlertClicked}>
        <div style={contentContainerStyle}>
          <div style={{ fontWeight: 500 }}>{this.props.alert.title}</div>
          <hr />
          <div style={{ fontSize: "12px" }}>{this.props.alert.description}</div>
        </div>
      </Paper>
    );
  }

  private handleAlertClicked = e => {
    this.props.alert.onClick();
  };
}
