import * as React from "react";
import { IAppointment } from "../../models/IAppointment";
import { ThemeColors } from "../../styles";

interface IComponentState {}

interface IComponentProps {
  appointment: IAppointment;
  color: string;
  showFade?: boolean;
}

const containerStyle: React.CSSProperties = {
  display: "flex",
  alignItems: "center",
  fontSize: "16px",
  paddingBottom: "16px",
  paddingLeft: "16px"
};

const circleStyle: React.CSSProperties = {
  borderRadius: "50%",
  width: "21px",
  height: "21px"
};

const stackedStyle: React.CSSProperties = {
  flex: "0 0 auto",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  paddingRight: "21px"
};

const overflowTextStyle: React.CSSProperties = {
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis"
};

export default class AppointmentListItem extends React.PureComponent<
  IComponentProps,
  IComponentState
> {
  public render() {
    const start = new Date(this.props.appointment.StartTime).toLocaleString(
      "en-US",
      {
        hour: "numeric",
        minute: "numeric",
        hour12: true
      }
    );

    const end = new Date(this.props.appointment.EndTime).toLocaleString(
      "en-US",
      {
        hour: "numeric",
        minute: "numeric",
        hour12: true
      }
    );

    const rowStyle = this.props.showFade
      ? { ...containerStyle, opacity: 0.5 }
      : containerStyle;

    return (
      <div style={rowStyle} onClick={this.handleClick}>
        <div
          style={{ paddingRight: "21px", paddingLeft: "5px", flex: "0 0 auto" }}
        >
          <div style={{ ...circleStyle, background: this.props.color }} />
        </div>
        <div style={{ ...stackedStyle, width: "80px" }}>
          <div>{start}</div>
          <div style={{ color: ThemeColors.SecondaryForeground }}>{end}</div>
        </div>
        <div style={stackedStyle}>
          <div style={overflowTextStyle}>{this.props.appointment.Title}</div>
          <div style={overflowTextStyle}>{this.props.appointment.Location}</div>
        </div>
      </div>
    );
  }

  private handleClick = (e: any) => {
    // if (getIsInMacWebview()) {
    //   (window as any).webkit.messageHandlers.scriptHandler.postMessage(
    //     `${InteropConstants.JS_MESSAGE_OPENAPPOINTMENT}${
    //       InteropConstants.MESSAGE_DELIMITER
    //     }${this.props.appointment.Id}`
    //   );
    // }
  };
}
