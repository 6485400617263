import AppBar from "@material-ui/core/AppBar/AppBar";
import Dialog from "@material-ui/core/Dialog";
import Divider from "@material-ui/core/Divider/Divider";
import IconButton from "@material-ui/core/IconButton/IconButton";
import List from "@material-ui/core/List/List";
import ListItem from "@material-ui/core/ListItem/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon/ListItemIcon";
import Toolbar from "@material-ui/core/Toolbar/Toolbar";
import Typography from "@material-ui/core/Typography/Typography";
import Close from "@material-ui/icons/Close";
import Launch from "@material-ui/icons/Launch";
import Settings from "@material-ui/icons/SettingsOutlined";
import AddToHome from "./AddToHome";
import * as React from "react";
import { ThemeColors } from "../styles";
import { getIsInMacWebview, InteropConstants } from "../utils";
import DrawerAlertContainer from "./Alerts/DrawerAlertContainer";
import SettingsPage from "./SettingsPage";

const leftDrawerContainerStyle: React.CSSProperties = {
  maxWidth: "300px",
  width: "100%",
  height: "100%",
  color: ThemeColors.PrimaryForeground,
  backgroundColor: ThemeColors.SecondaryBackground,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "stretch"
};

const topContainerStyle: React.CSSProperties = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "stretch"
};

const bodyContainerStyle: React.CSSProperties = {
  color: ThemeColors.PrimaryForeground,
  display: "flex",
  flex: 1,
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "stretch",
  padding: "10px"
};

const bottomContainerStyle: React.CSSProperties = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-end",
  alignItems: "stretch"
};

interface IComponentProps {
  onClose: () => void;
}

interface IComponentState {
  version?: string;
  showSettingsDialog: boolean;
}

export default class LeftDrawer extends React.Component<
  IComponentProps,
  IComponentState
> {
  constructor(props: IComponentProps) {
    super(props);

    this.state = { version: undefined, showSettingsDialog: false };

    if (getIsInMacWebview()) {
      window.addEventListener(
        InteropConstants.NATIVE_EVENT_EVENTNAME,
        this.handleVersionEventFromNative
      );

      (window as any).webkit.messageHandlers.scriptHandler.postMessage(
        "getVersion"
      );
    }
  }

  public render() {
    return (
      <div style={leftDrawerContainerStyle}>
        <div style={topContainerStyle}>
          <AppBar
            style={{
              position: "relative",
              backgroundColor: ThemeColors.SecondaryBackground,
              color: ThemeColors.PrimaryForeground
            }}
          >
            <Toolbar>
              <Typography
                variant="title"
                color="inherit"
                style={{ flex: "1", cursor: "pointer" }}
              >
                Clean Calendar
              </Typography>
              <IconButton
                color="inherit"
                onClick={this.props.onClose}
                aria-label="Menu"
              >
                <Close style={{ opacity: 20 }} />
              </IconButton>
            </Toolbar>
          </AppBar>
        </div>
        <div style={bodyContainerStyle}>
          <DrawerAlertContainer goToSettingsFunc={this.handleSettingsClicked} />
        </div>
        <div style={bottomContainerStyle}>
          <List>
            {!!this.state.version && (
              <ListItem>
                <div className="secondary-foreground">
                  Version {this.state.version}
                </div>
              </ListItem>
            )}
            <ListItem
              className="primary-foreground"
              button
              onClick={this.handleSettingsClicked}
            >
              <ListItemIcon>
                <Settings style={{ color: ThemeColors.PrimaryForeground }} />
              </ListItemIcon>
              Settings
            </ListItem>
            <ListItem
              className="primary-foreground"
              button
              onClick={this.handleHelpClick}
            >
              Support
              <span>
                <Launch style={{ fontSize: "85%", marginLeft: "1em" }} />
              </span>
            </ListItem>
            {getIsInMacWebview() && (
              <ListItem
                className="primary-foreground"
                button
                onClick={this.handleQuitClick}
              >
                Quit
              </ListItem>
            )}
          </List>
          <Divider />
          <AddToHome />
        </div>
        <Dialog
          fullScreen
          open={this.state.showSettingsDialog}
          onClose={this.handleSettingsDialogClosed}
        >
          <SettingsPage onClose={this.handleSettingsDialogClosed} />
        </Dialog>
      </div>
    );
  }

  public componentWillUnmount() {
    if (getIsInMacWebview()) {
      window.removeEventListener(
        InteropConstants.NATIVE_EVENT_EVENTNAME,
        this.handleVersionEventFromNative
      );
    }
  }

  private handleSettingsDialogClosed = () => {
    this.setState({ ...this.state, showSettingsDialog: false });
  };

  private handleSettingsClicked = event => {
    this.setState({ ...this.state, showSettingsDialog: true });
  };

  private handleVersionEventFromNative = event => {
    const name = event.detail.name;
    const version = event.detail.returnString;
    if (name === "version" && !!version) {
      this.setState({ ...this.state, version });
    }
  };

  private handleQuitClick = event => {
    (window as any).webkit.messageHandlers.scriptHandler.postMessage("quit");
  };

  private handleHelpClick = event => {
    event.preventDefault();
    if (getIsInMacWebview()) {
      (window as any).webkit.messageHandlers.scriptHandler.postMessage("help");
    } else {
      window.open("https://cleancalendar.app/#/support");
    }

    this.props.onClose();
  };
}
