import * as React from "react";

export enum Constants {
  DEFAULT_SELECTED_DATE_TIMEOUT_MINUTES = 30
}

export interface ISelectedDateContext {
  selectedDate: Date;
  today: Date;
  selectDate: (Date) => void;
}

export const SelectedDateContext = React.createContext({});
