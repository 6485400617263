import List from "@material-ui/core/List";
import * as React from "react";
import { AlertsContext, IAlertsContext } from "../../contexts/alerts-context";
import { AlertLevel, ISimpleAlert } from "../../models/Alerts";
import SimpleDrawerAlert from "./SimpleDrawerAlert";

interface IComponentProps {
  alertsContext: IAlertsContext;
  goToSettingsFunc: () => void;
}

const DrawerAlertContainer: React.SFC<IComponentProps> = props => {
  const simpleAlerts: ISimpleAlert[] = [];

  if (props.alertsContext.enableShowAppointmentsDrawerAlert) {
    simpleAlerts.push({
      level: AlertLevel.Info,
      title: "See Your Appointments",
      description:
        "To see your appointments you must give Clean Calendar permission to access your appointment data",
      onClick: () => props.alertsContext.showAppointmentsDrawerAlertResult(true)
    });
  } else if (props.alertsContext.enableNoCalendarsDrawerAlert) {
    simpleAlerts.push({
      level: AlertLevel.Info,
      title: "All Calendars are Hidden",
      description:
        "To see your appointments you must turn on at least one calendar.",
      onClick: () => props.goToSettingsFunc()
    });
  }

  return (
    <List>
      {simpleAlerts.map((sa, index) => {
        const key = `draw-alert-${index}`;
        return <SimpleDrawerAlert key={key} alert={sa} />;
      })}
    </List>
  );
};

export default props => (
  <AlertsContext.Consumer>
    {context => <DrawerAlertContainer {...props} alertsContext={context} />}
  </AlertsContext.Consumer>
);
