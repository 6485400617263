import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import * as React from "react";
import { ThemeColors } from "../../styles";

interface IComponentProps {
  showAppointmentsAlertResult: (showAppointments: boolean) => void;
}

const alertContainerStyle: React.CSSProperties = {
  backgroundColor: ThemeColors.WarningAlertBackground,
  color: ThemeColors.WarningAlertForeground,
  height: "100%",
  display: "flex",
  alignContent: "center",
  flexDirection: "row",
  justifyContent: "space-between",
  fontSize: "16px"
};

const textContainerStyle: React.CSSProperties = {
  display: "flex",
  justifyContent: "center",
  flexDirection: "row",
  userSelect: "none",
  padding: "12px 16px"
};

const closeButtonStyle: React.CSSProperties = {
  paddingRight: "12px",
  fontSize: "9px",
  alignSelf: "center"
};

const infoTextStyle: React.CSSProperties = {
  alignSelf: "center"
};

const actionsContainerStyle: React.CSSProperties = {
  minWidth: "200px",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignContent: "center",
  userSelect: "none",
  padding: "12px 16px"
};

const buttonContainerStyle: React.CSSProperties = {
  alignSelf: "center",
  color: ThemeColors.AccentForeground,
  padding: "5px"
};

export default class ShowAppointmentsAlert extends React.PureComponent<
  IComponentProps,
  {}
> {
  public render() {
    return (
      <div style={alertContainerStyle}>
        <div style={textContainerStyle}>
          <div style={closeButtonStyle}>
            <IconButton
              color="inherit"
              onClick={this.handleNoClick}
              aria-label="Close Alert"
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div style={infoTextStyle}>
            Would you like to see your appointments in Clean Calendar?
          </div>
        </div>
        <div style={actionsContainerStyle}>
          <div style={buttonContainerStyle}>
            <Button color="inherit" onClick={this.handleNoClick}>
              Not now
            </Button>
          </div>
          <div style={buttonContainerStyle}>
            <Button
              variant="contained"
              color="inherit"
              onClick={this.handleYesClick}
            >
              Yes
            </Button>
          </div>
        </div>
      </div>
    );
  }

  private handleYesClick = e => {
    this.props.showAppointmentsAlertResult(true);
  };

  private handleNoClick = e => {
    this.props.showAppointmentsAlertResult(false);
  };
}
