import * as React from "react";
import { ThemeColors } from "../styles";

interface IComponentProps {
  date: Date;
}

const iconContainerStyle: React.CSSProperties = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "center",
  color: ThemeColors.AccentForeground
};

const dayNameStyle: React.CSSProperties = {
  alignSelf: "stretch",
  fontSize: "9px",
  lineHeight: "12px",
  borderStyle: "solid",
  borderWidth: "1.1px",
  borderBottom: 0
};

const dayNumberStyle: React.CSSProperties = {
  alignSelf: "stretch",
  fontSize: "16px",
  padding: "0px 5px",
  borderStyle: "solid",
  borderWidth: "1.1px",
  boxSizing: "border-box"
};

export const DateIcon: React.SFC<IComponentProps> = props => {
  return (
    <div style={iconContainerStyle}>
      <div style={dayNameStyle}>
        {props.date.toLocaleDateString("en-US", {
          weekday: "short"
        })}
      </div>
      <div style={dayNumberStyle}>{props.date.getDate()}</div>
    </div>
  );
};
