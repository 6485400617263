import { isPast, isToday } from "date-fns";
import * as React from "react";
import {
  CalendarsContext,
  ICalendarsContext
} from "../../contexts/calendars-context";
import { IAppointment } from "../../models/IAppointment";
import { ThemeColors } from "../../styles";
import {
  getIsInMacWebview,
  InteropConstants,
  toYearMonthDayString
} from "../../utils";
import AppointmentListAllDayItem from "./AppointmentListAllDayItem";
import AppointmentListItem from "./AppointmentListItem";

interface IComponentState {}

interface IComponentProps {
  date: Date;
  appointments?: IAppointment[];
  calendarsContext: ICalendarsContext;
}

const containerStyle: React.CSSProperties = {
  height: "200px",
  display: "flex",
  flexDirection: "column",
  paddingTop: "16px",
  backgroundColor: ThemeColors.SecondaryBackground,
  color: ThemeColors.PrimaryForeground
};

const dayHeaderStyle: React.CSSProperties = {
  paddingBottom: "16px",
  paddingLeft: "16px",
  color: ThemeColors.AccentForeground,
  fontWeight: 800
};

const noAppointmentsMessageStyle: React.CSSProperties = {
  height: "100%",
  opacity: 0.3,
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
};

class AppointmentList extends React.PureComponent<
  IComponentProps,
  IComponentState
> {
  public render() {
    const prettyDate = this.props.date.toLocaleDateString("en-US", {
      weekday: "long",
      year: "numeric",
      day: "numeric",
      month: "long"
    });

    return (
      <div style={containerStyle}>
        <div style={dayHeaderStyle}>{prettyDate}</div>
        {this.props.appointments &&
          this.props.appointments.length > 0 && (
            <div style={{ overflowY: "auto" }}>
              {this.props.appointments.map(this.renderAppointmentRow)}
            </div>
          )}
        {(!this.props.appointments || this.props.appointments.length < 1) && (
          <div style={noAppointmentsMessageStyle}>
            <div>No Appointments</div>
          </div>
        )}
      </div>
    );
  }

  private renderAppointmentRow = (
    appointment: IAppointment,
    index: number,
    array: IAppointment[]
  ) => {
    const shouldFade =
      isPast(appointment.EndTime) ||
      (appointment.IsAllDay &&
        isPast(this.props.date) &&
        !isToday(this.props.date));

    let color = "#ffffff";
    if (this.props.calendarsContext.calendars) {
      const filteredCals = this.props.calendarsContext.calendars.filter(
        c => c.Id === appointment.CalendarId
      );
      if (filteredCals.length > 0) {
        color = filteredCals[0].Color;
      }
    }

    if (appointment.IsAllDay) {
      return (
        <div key={`aptlist-${index}`} onClick={this.handleAppointmentClick}>
          <AppointmentListAllDayItem
            appointment={appointment}
            showFade={shouldFade}
            color={color}
          />
        </div>
      );
    }

    return (
      <div key={`aptlist-${index}`} onClick={this.handleAppointmentClick}>
        <AppointmentListItem
          appointment={appointment}
          showFade={shouldFade}
          color={color}
        />
      </div>
    );
  };

  private handleAppointmentClick = (e: any) => {
    if (getIsInMacWebview()) {
      (window as any).webkit.messageHandlers.scriptHandler.postMessage(
        `${InteropConstants.JS_MESSAGE_OPENDATE}${
          InteropConstants.MESSAGE_DELIMITER
        }${toYearMonthDayString(this.props.date)}`
      );
    }
  };
}

export default props => (
  <CalendarsContext.Consumer>
    {context => <AppointmentList {...props} calendarsContext={context} />}
  </CalendarsContext.Consumer>
);
