export enum AlertLevel {
  Info,
  Warning,
  Error
}

export interface IAlert {
  title: string;
  description: string;
  level: AlertLevel;
}

export interface ISimpleAlert extends IAlert {
  onClick: () => void;
}
