import * as React from "react";

export interface IAlertsContext {
  enableShowAppointmentsAlert: boolean;
  showAppointmentsAlertResult: (showAppointments: boolean) => void;
  enableShowAppointmentsDrawerAlert: boolean;
  showAppointmentsDrawerAlertResult: (showAppointments: boolean) => void;
  enableNoCalendarsDrawerAlert: boolean;
}

export const AlertsContext = React.createContext({});
