import * as React from "react";
import { ThemeColors } from "../styles";

interface IComponentProps {
  date: Date;
}

const fullDateStyle: React.CSSProperties = {
  color: ThemeColors.SecondaryForeground,
  fontSize: "calc(35% + 0.6vw + 0.2vh)"
};

export const MonthDayHoverContent: React.SFC<IComponentProps> = props => {
  return (
    <div style={fullDateStyle}>
      {props.date.toLocaleDateString("en-US", {
        weekday: "short"
      })}
    </div>
  );
};
