import * as React from "react";
import { ThemeColors } from "../styles";

interface IComponentProps {}

const dayOfWeekCellStyle: React.CSSProperties = {
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  textAlign: "center",
  userSelect: "none"
};

const containerStyle: React.CSSProperties = {
  height: "100%",
  color: ThemeColors.SecondaryForeground,
  display: "grid",
  gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr 1fr",
  backgroundColor: ThemeColors.SecondaryBackground
};

export const MonthDayOfWeekRow: React.SFC<IComponentProps> = props => {
  return (
    <div style={containerStyle}>
      <div style={dayOfWeekCellStyle}>Sun</div>
      <div style={dayOfWeekCellStyle}>Mon</div>
      <div style={dayOfWeekCellStyle}>Tue</div>
      <div style={dayOfWeekCellStyle}>Wed</div>
      <div style={dayOfWeekCellStyle}>Thu</div>
      <div style={dayOfWeekCellStyle}>Fri</div>
      <div style={dayOfWeekCellStyle}>Sat</div>
    </div>
  );
};
