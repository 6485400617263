import MonthCalendar from "./components/MonthCalendar";
import SupportPage from "./components/SupportPage";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { HashRouter as Router, Route } from "react-router-dom";
import "typeface-roboto";
// import registerServiceWorker from "./registerServiceWorker";

if (process.env.NODE_ENV !== "production") {
  // tslint:disable-next-line:no-var-requires
  const { whyDidYouUpdate } = require("why-did-you-update");
  whyDidYouUpdate(React);
}

ReactDOM.render(
  <Router>
    <div style={{ height: "100%" }}>
      <Route exact path="/" component={MonthCalendar} />
      <Route exact path="/support" component={SupportPage} />
    </div>
  </Router>,
  document.getElementById("root")
);

// registerServiceWorker();
