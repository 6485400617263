import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import { addMonths } from "date-fns";
import * as React from "react";
import { ThemeColors } from "../styles";

interface IComponentState {}

// Using year and month as numbers because PureComponent compares values of
// primitives, but a Date gets compared by reference, so two Date objects
// pointing to the same day are not equal and thus this simple, pure component
// would re-render even when it doesn't need to... so I am avoiding Date in
// the props
interface IComponentProps {
  year: number;
  month: number;
  onNextClick: () => void;
  onPreviousClick: () => void;
  onDateClick: () => void;
}

const headerContainerStyle: React.CSSProperties = {
  height: "100%",
  display: "flex",
  alignContent: "center",
  flexDirection: "row",
  justifyContent: "space-between",
  backgroundColor: ThemeColors.AccentBackground,
  fontWeight: 200,
  color: ThemeColors.PrimaryForeground
};

const arrowButtonContainerStyle: React.CSSProperties = {
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  userSelect: "none",
  paddingLeft: "10px",
  paddingRight: "10px",
  backgroundColor: "#ffffff33",
  fontSize: "32px"
};

const monthTextStyle: React.CSSProperties = {
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  userSelect: "none",
  fontSize: "26px"
};

export default class MonthHeader extends React.PureComponent<
  IComponentProps,
  IComponentState
> {
  public render() {
    const date = new Date(this.props.year, this.props.month, 1);

    const options = { year: "numeric", month: "long" };
    const prettyDate = date.toLocaleDateString("en-US", options);

    const prevMonth = addMonths(date, -1).toLocaleDateString("en-US", {
      month: "long"
    });
    const nextMonth = addMonths(date, 1).toLocaleDateString("en-US", {
      month: "long"
    });

    return (
      <div style={headerContainerStyle}>
        <Tooltip
          title={`${prevMonth} ( ← )`}
          enterDelay={400}
          TransitionComponent={Zoom}
        >
          <div
            style={arrowButtonContainerStyle}
            className="opacity-on-hover"
            onClick={this.handlePreviousMonthClicked}
          >
            <div>&#x3c;</div>
          </div>
        </Tooltip>
        <Tooltip
          title="Jump to Today ( t )"
          enterDelay={400}
          TransitionComponent={Zoom}
        >
          <div
            style={monthTextStyle}
            className="opacity-on-hover"
            onClick={this.handleMonthClicked}
          >
            {prettyDate}
          </div>
        </Tooltip>
        <Tooltip
          title={`${nextMonth} ( → )`}
          enterDelay={400}
          TransitionComponent={Zoom}
        >
          <div
            style={arrowButtonContainerStyle}
            className="opacity-on-hover"
            onClick={this.handleNextMonthClicked}
          >
            <div>&#x3e;</div>
          </div>
        </Tooltip>
      </div>
    );
  }

  private handleMonthClicked = evt => {
    this.props.onDateClick();
  };

  private handlePreviousMonthClicked = evt => {
    this.props.onPreviousClick();
  };

  private handleNextMonthClicked = evt => {
    this.props.onNextClick();
  };
}
