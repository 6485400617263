import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import Menu from "@material-ui/icons/Menu";
import { isSameDay } from "date-fns";
import * as React from "react";
import { ThemeColors } from "../styles";
import { getIsInMacWebview, InteropConstants } from "../utils";
import { DateIcon } from "./DateIcon";

interface IComponentState {
  today: Date;
}

interface IComponentProps {
  onMenuClick: () => void;
  onTodayClick: () => void;
}

const footerContainerStyle: React.CSSProperties = {
  height: "50px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "0px 10px",
  backgroundColor: ThemeColors.PrimaryBackground,
  color: ThemeColors.AccentForeground
};

const footerButtonStyle = { cursor: "default" };

export default class MonthFooter extends React.PureComponent<
  IComponentProps,
  IComponentState
> {
  constructor(props: IComponentProps) {
    super(props);

    this.state = {
      today: new Date()
    };

    if (getIsInMacWebview()) {
      window.addEventListener(
        InteropConstants.NATIVE_EVENT_EVENTNAME,
        this.handleEventFromNative
      );
    }
  }

  public componentWillUnmount() {
    if (getIsInMacWebview()) {
      window.removeEventListener(
        InteropConstants.NATIVE_EVENT_EVENTNAME,
        this.handleEventFromNative
      );
    }
  }

  public render() {
    return (
      <div style={footerContainerStyle}>
        <Button
          color="inherit"
          onClick={this.handleMenuClick}
          style={footerButtonStyle}
        >
          <Menu style={{ fontSize: "28px" }} />
        </Button>
        <Tooltip
          title="Jump to Today ( t )"
          enterDelay={400}
          TransitionComponent={Zoom}
        >
          <Button
            color="inherit"
            onClick={this.handleTodayClick}
            style={footerButtonStyle}
          >
            <DateIcon date={this.state.today} />
          </Button>
        </Tooltip>
      </div>
    );
  }

  private handleMenuClick = event => {
    this.props.onMenuClick();
  };

  private handleTodayClick = event => {
    this.props.onTodayClick();
  };

  private handleEventFromNative = event => {
    const name = event.detail.name;
    if (name === InteropConstants.NATIVE_EVENT_POPOVERWILLSHOW_DETAILNAME) {
      // only update "today" if it has changed
      const newToday = new Date();
      if (!isSameDay(this.state.today, newToday)) {
        this.setState(state => ({ ...state, today: newToday }));
      }
    }
  };
}
