export enum ThemeColors {
  PrimaryBackground = "#000000",
  SecondaryBackground = "#2C2828",
  PrimaryForeground = "#ffffff",
  SecondaryForeground = "#969696",
  AccentForeground = "#3255c2",
  AccentBackground = "#2c4ba9",
  WarningAlertBackground = "#cbcb00",
  WarningAlertForeground = "#2c2828"
}
