import {
  addDays,
  addHours,
  addMinutes,
  isSameDay,
  setDate,
  setHours
} from "date-fns";
import { appointmentComparer, IAppointment } from "./IAppointment";
import IMonthAppointmetsPayload from "./IMonthAppointmetsPayload";
import { ICalendar } from "./ICalendar";
import { InteropConstants } from "../utils";

const disabledCalendars: Set<string> = new Set();

export function disableCalendar(calendarId: string) {
  disabledCalendars.add(calendarId);
  raiseJsEvent(InteropConstants.NATIVE_EVENT_CALENDARS_UPDATED);
}

export function enableCalendar(calendarId: string) {
  disabledCalendars.delete(calendarId);
  raiseJsEvent(InteropConstants.NATIVE_EVENT_CALENDARS_UPDATED);
}

export function getMonthAppointments(
  yearMonthString: string // YYYY-MM format
): Promise<IMonthAppointmetsPayload> {
  return new Promise((resolve, reject) => {
    // creating new date with day set to 15 so we don't have to worry about UTC
    // falling on the last day of the previous month... and then we manually
    // set the day to the 1st of the month
    let date = new Date(`${yearMonthString}-15`);
    date = setDate(date, 1);

    // get appointments starting 7 days before the first of the month
    const startDate = addDays(date, -7);
    // get appointments stopping about 7 days after the end of the month (some
    // months aren't fully 31 days but that's fine)
    const endDate = addDays(date, 37);

    const appts: IAppointment[] = [];

    const titles = [
      "Tax Appointment",
      "Karen's Birthday",
      "Meeting with the Bob's",
      "Soccer practice",
      "Review FY19 Numbers",
      "Yet Another Birthday Party",
      "Jim's Birthday",
      "Lunch with Kim",
      "Library Books due back",
      "Take van to shop",
      "Replace tires on truck",
      "Jack's birthday",
      "Aaron's birthday",
      "Some other holiday",
      "Pizza with those people",
      "Another made up appointment"
    ];

    const locations = [
      "",
      "123 Seesaw Street",
      "Conference Room A",
      "The big room with the table",
      "9823 Williams Road",
      "Conference Room 12",
      "",
      "",
      ""
    ];

    const numberOfAppointmentsPerDay = [
      2,
      1,
      0,
      3,
      2,
      1,
      1,
      1,
      0,
      0,
      3,
      0,
      2,
      3,
      5,
      4,
      1,
      1,
      0,
      3,
      4,
      1,
      0,
      1,
      2,
      2
    ];

    const cal1Id = "123";
    const cal1Disabled = disabledCalendars.has(cal1Id);
    const calendar1: ICalendar = {
      AccountName: "Work",
      Title: "Team Calendar",
      Id: cal1Id,
      Hash: `123${cal1Disabled}`,
      Color: "#3255c2",
      IsDisabled: cal1Disabled
    };

    const cal2Id = "456";
    const cal2Disabled = disabledCalendars.has(cal2Id);
    const calendar2: ICalendar = {
      AccountName: "Outlook",
      Title: "Calendar",
      Id: cal2Id,
      Hash: `456${cal2Disabled}`,
      Color: "#64189F",
      IsDisabled: cal2Disabled
    };

    const cals = [calendar1, calendar2, { ...calendar1 }];

    let dayCounter = 0;
    let titleLocationCounter = 0;
    let dayWalker = startDate;
    const dayAfterEndDate = addDays(endDate, 1);
    while (!isSameDay(dayWalker, dayAfterEndDate)) {
      const numApptsForDay =
        numberOfAppointmentsPerDay[
          dayCounter % numberOfAppointmentsPerDay.length
        ];
      for (let index = 0; index < numApptsForDay; index += 1) {
        const isAllDay = titleLocationCounter % 4 === 0;
        const hourOffset = titleLocationCounter % 8;
        let start = setHours(dayWalker, 8 + hourOffset);
        let end = addMinutes(start, titleLocationCounter % 1 === 1 ? 30 : 60);

        if (isAllDay) {
          start = setHours(dayWalker, 0);
          end = addHours(start, 24);
        }
        const cal = cals[titleLocationCounter % 2];
        if (!cal.IsDisabled) {
          appts.push({
            CalendarId: cal.Id,
            StartTime: start.getTime(),
            EndTime: end.getTime(),
            IsAllDay: isAllDay,
            Location: locations[titleLocationCounter % locations.length],
            Title: titles[titleLocationCounter % titles.length],
            Id: titleLocationCounter.toString(),
            Hash: titleLocationCounter.toString()
          });
        }
        titleLocationCounter += 1;
      }
      dayWalker = addDays(dayWalker, 1);
      dayCounter += 1;
    }

    appts.sort(appointmentComparer);

    // TODO: implement better hash... actually just implement a much better
    // mock appt service
    const hash = `${yearMonthString}${calendar1.Hash}${calendar2.Hash}`;

    resolve({
      YearMonthString: yearMonthString,
      Hash: hash,
      Appointments: appts,
      Calendars: [calendar1, calendar2]
    });
  });
}

function raiseJsEvent(eventName: string, returnString: string = "") {
  window.dispatchEvent(
    new CustomEvent("eventFromNative", {
      detail: { name: eventName, returnString: returnString },
      bubbles: true,
      cancelable: true
    })
  );
}
